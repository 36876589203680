import styled, { css } from 'styled-components';
import BaseBanner, {
  BaseBannerProps,
} from '@/modules/home/components/BaseBanner/BaseBanner';

type PlaylistColumn = {
  id: number;
} & BaseBannerProps;

type PlayListRow = {
  id: number;
  columns: PlaylistColumn[];
};

type DynamicPlaylistProps = {
  playlist: PlayListRow[];
};

type GridProps = {
  columns: number;
};

const Grid = styled.div<GridProps>`
  ${({ columns, theme }) => css`
    display: grid;
    grid-template-columns: repeat(${columns}, 1fr);
    grid-gap: ${theme.spaces[200]};
  `}
`;

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    height: auto;
    overflow: hidden;

    width: 100%;
    box-sizing: border-box;

    content-visibility: auto;
    contain-intrinsic-size: 6rem 240rem;

    text-align: center;
    ${Grid}:not(:first-of-type) {
      padding-top: ${theme.spaces[200]};
    }
  `}
`;

const DynamicPlaylist = ({ playlist }: DynamicPlaylistProps) => {
  return (
    <Container data-testid="dynamic-playlist">
      {playlist.map((row) => {
        return (
          <Grid key={row.id} columns={row.columns.length}>
            {row.columns.map((column) => {
              return (
                <BaseBanner
                  testId="dynamic-playlist-item"
                  key={column.id}
                  {...column}
                />
              );
            })}
          </Grid>
        );
      })}
    </Container>
  );
};

export default DynamicPlaylist;
